<div class="even">
<mat-label class="subHeading ">Vehicle Age<span *ngIf="isRequired" class="star">*</span></mat-label>
<button class="side" (click)="vehicleAgeForm.controls['from'].setValue(null);vehicleAgeForm.controls['to'].setValue(null)">Remove</button>
</div>

<div formGroupName="vehicle_age" class="d-flex align-items-center mt-2" style="gap: 10px;">
    <mat-select formControlName="from" class="w-50 m-0" placeholder="Select">
        <mat-option *ngFor="let age of fromAges" [value]="age">{{age}}</mat-option>
    </mat-select>
    <mat-select formControlName="to" class="w-50 m-0" placeholder="Select">
        <mat-option *ngFor="let age of toAges" [value]="age">{{age}}</mat-option>
    </mat-select>
</div>
