import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-dob-field',
  templateUrl: './dob-field.component.html',
  styleUrls: ['./dob-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  encapsulation: ViewEncapsulation.None
})
export class DobFieldComponent implements OnInit {
  @Input('label') label: string="DOB";
  today = new Date(new Date().getFullYear()-1, new Date().getMonth(), new Date().getDate());

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    this.form.addControl('proposer_dob', new FormControl(null))
  }

  ngOnDestroy(): void {
    this.form.removeControl('proposer_dob');
  }
}
