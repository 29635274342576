import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { sort, removeDuplicates } from 'src/app/core/utils/utility.functions';
import { FormControlService } from 'src/app/shared/services/form-control.service';


@Component({
  selector: 'app-make-model-variant',
  templateUrl: './make-model-variant.component.html',
  styleUrls: ['./make-model-variant.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class MakeModelVariantComponent implements OnInit {
  @Input('lob') lob: string = 'motor';
  @Input('required') isRequired = false;
  @Input('showVariant') showVariant = false;

  form: FormGroup;

  makeList: any = [];
  modelList: any = [];
  variantList: any = [];

  makeControl:any = new FormControl();
  modelControl:any = new FormControl();
  variantControl: any = new FormControl();
  
  mappingControl = new FormControl(main_mapping);
  
  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService, private controlStateService: FormControlService) {
    this.controlStateService.register({ controlName: 'make', control: this.makeControl });
    this.controlStateService.register({ controlName: 'model', control: this.modelControl });
    if (this.showVariant) {
      this.controlStateService.register({ controlName: 'variant', control: this.variantControl });
    }
    this.subscribeToValueChangesV2();
  };

  ngOnInit() {
    if (this.isRequired) {
      this.makeControl.addValidators(Validators.required);
      this.modelControl.addValidators(Validators.required);
      this.variantControl.addValidators(Validators.required);
    }

    this.form = this.ctrlContainer.form;
    // this.subscribeToValueChanges();

    if (this.form.controls['make']) {
      this.makeControl.patchValue(this.form.controls['make'].value);
      this.form.removeControl('make');
    }
    if (this.form.controls['model']) {
      this.modelControl.patchValue(this.form.controls['model'].value);
      this.form.removeControl('model');
    }
    if (this.form.controls['variant']) {
      this.variantControl.patchValue(this.form.controls['variant'].value);
      this.form.removeControl('variant');
    }

    this.form.addControl('make', this.makeControl);
    this.form.addControl('model', this.modelControl);
    
    if (this.showVariant) {
      this.form.addControl('variant', this.variantControl);
    }

    // this.form.addControl('make_model_mapping', this.mappingControl);
    this.getMake();
  }


  getMake(vehicleCategory: any = null, vehicleClass: any = null) {
    if (this.lob == 'motor' || this.lob == 'motor4W') {
      if (this.lob == 'motor') {
        vehicleClass = 1;
      } else if (this.lob == 'motor4W') {
        vehicleClass = 0;
      }
      const url = '/services/api/v1/rb_masters/make_based_on_vehicle_category';
      let params = { "category": vehicleCategory || [], 'vehicle_class': vehicleClass };
      this.http.get(url, params).subscribe((res: any) => {
        this.makeList = sort(res['data'], 'manufacturer_name');
        // this.mapAll();
      });
    } else {
      const url = '/services/api/v1/rb_masters/manufacturers';
      let params:{[key:string]: any} = {};
      if (vehicleCategory && vehicleCategory.length) {
        params["category"]= vehicleCategory ;
      }
      if (vehicleClass && vehicleClass.length) {
        params['vehicle_class'] = vehicleClass
      }
      this.http.get(url, params).subscribe((res: any) => {
        this.makeList = sort(res['data'], 'manufacturer_name');
        // this.mapAll();
      });
    }
  }

  getModel(makeIds: any) {
    const url = `/services/api/v1/rb_masters/get-vehicle-models`;
    const params = {
      'lob': this.lob
    };
    let category = null;
    if (this.form.controls['vehicle_category'] && this.form.controls['vehicle_category'].value && this.form.controls['vehicle_category'].value.length) {
      category = this.form.controls['vehicle_category'].value;
    }
    const data = { 'manufacturer_id': makeIds, 'category':  category};
    this.http.post(url, data, params).subscribe((res: any) => {
      this.modelList = sort(res['data'], 'model_name');
      // this.mapAll();
    });
  }

  getVariant(modelIds: any) {
      const url = `/services/api/v1/rb_masters/get-vehicle-model-variants`;
      const params = {
        'lob': this.lob,
      };
      const data = { 'vehiclemodel_id': modelIds };
      this.http.post(url, data, params).subscribe((res: any) => {
        this.variantList = sort(res['data'], 'variant_name');
        // this.mapAll();
      });
  }

  subscribeToValueChanges() {
    if (this.form.controls['vehicle_category']) {
      this.form.controls['vehicle_category'].valueChanges.subscribe((val: any) => {
        if (val != null) {
          if (this.form.controls['vehicle_class'] && this.form.controls['vehicle_class'].value && this.form.controls['vehicle_class'].value.length) {
            this.getMake(val, this.form.controls['vehicle_class'].value);
          } else {
            this.getMake(val, null);
          }
        }
      });
    }

    if (this.form.controls['vehicle_class']) {
      this.form.controls['vehicle_class'].valueChanges.subscribe((val: any) => {
        if (val != null) {
          if (this.form.controls['vehicle_category'] && this.form.controls['vehicle_category'].value && this.form.controls['vehicle_category'].value.length) {
            this.getMake(this.form.controls['vehicle_category'].value, val);
          } else {
            this.getMake(null,val);
          }
        }
      });
    }

    this.makeControl.valueChanges.subscribe(
      (val: any) => {
        if (val && val.length) {
          this.getModel(val);
        }
        else {
          this.modelList = [];
          this.variantList = [];
          // this.modelControl.patchValue(null);
          // this.variantControl.patchValue(null);
        }
        this.mapStates(val);
      }
    );

    this.modelControl.valueChanges.subscribe(
      (val: any) => {
        if (val && val.length) {
          this.getVariant(val);
        }
        else {
          this.variantList = [];
          // this.variantControl.patchValue(null);
        }
        this.mapCities(val);
      }
    );

    this.variantControl.valueChanges.subscribe((val: any) => {
      this.mapVariants(val);
    });
  }

  subscribeToValueChangesV2() {
    if (this.controlStateService.isRegistered('vehicle_category')) {
      this.controlStateService.getControlObservable('vehicle_category').subscribe((val: any) => {
        if (val != null) {
          if (this.form.controls['vehicle_class'] && this.form.controls['vehicle_class'].value && this.form.controls['vehicle_class'].value.length) {
            this.getMake(val, this.form.controls['vehicle_class'].value);
          } else {
            this.getMake(val, null);
          }
        }
      });
    }

    if (this.controlStateService.isRegistered('vehicle_class')) {
      this.controlStateService.getControlObservable('vehicle_class').subscribe((val: any) => {
        if (val != null) {
          if (this.form.controls['vehicle_category'] && this.form.controls['vehicle_category'].value && this.form.controls['vehicle_category'].value.length) {
            this.getMake(this.form.controls['vehicle_category'].value, val);
          } else {
            this.getMake(null,val);
          }
        }
      });
    }

    this.controlStateService.getControlObservable('make').subscribe(
      (val: any) => {
        if (val && val.length) {
          this.getModel(val);
        }
        else {
          this.modelList = [];
          this.variantList = [];
          // this.modelControl.patchValue(null);
          // this.variantControl.patchValue(null);
        }
        // this.mapStates(val);
      }
    );

    this.controlStateService.getControlObservable('model').subscribe(
      (val: any) => {
        if (val && val.length) {
          this.getVariant(val);
        }
        else {
          this.variantList = [];
          // this.variantControl.patchValue(null);
        }
        // this.mapCities(val);
      }
    );

  }

  ngOnDestroy(): void {
    this.form.removeControl('make');
    this.form.removeControl('model');
    if (this.showVariant) {
      this.form.removeControl('variant');
    }
    
  }

  mapAll() {
    if (
      (!this.makeList || !this.makeList.length)
      ||
      (!this.modelList || !this.modelList.length)
      ||
      (!this.variantList || !this.variantList.length)
    ) { return };

    this.mapStates(this.makeControl.value);
    this.mapCities(this.modelControl.value);
    this.mapVariants(this.variantControl.value);
  }

  mapStates(states: any) {
    if (
      (!this.makeList || !this.makeList.length)
    ) { return };
    for (const make of states) {
      if (!main_mapping.find((val: any) => val.make == make)) {
        const obj: any = { 'make': make, 'model': null, 'variant': null };
        main_mapping.push(obj);
      }
    }

    const toRemove = [];
    for (const [idx, obj] of main_mapping.entries()) {
      if (obj.make && states.indexOf(obj.make) === -1) {
        toRemove.push(idx);
      }
    }
    toRemove.forEach((idx: any) => main_mapping.splice(idx, 1));
  }

  mapCities(cities: any) {
    if (
      (!this.makeList || !this.makeList.length)
      ||
      (!this.modelList || !this.modelList.length)
    ) { return };
    for (const model of cities) {
      const city_obj = this.modelList.find((obj: any) => obj.rb_id == model);
      const make = city_obj['manufacturer_id'] || city_obj['manufacturer'];
      if (!main_mapping.find((val: any) => val.model == model)) {
        let obj = main_mapping.find((val: any) => val.make == make && !val.model);
        if (!obj) {
          obj = { 'make': make, 'model': model, 'variant': null };
          main_mapping.push(obj);
        } else {
          obj.model = model;
        }
      }
    }

    const toRemove = [];
    for (const [idx, obj] of main_mapping.entries()) {
      if (obj.model && cities.indexOf(obj.model) === -1) {
        toRemove.push(idx);
      }
    }
    toRemove.forEach((idx: any) => {
      if (main_mapping.filter((obj: any) => obj.make == main_mapping[idx]['make']).length > 1) {
        main_mapping.splice(idx, 1);
      } else {
        main_mapping[idx]['model'] = null;
        main_mapping[idx]['variant'] = null;
      }
    });
    main_mapping = removeDuplicates(main_mapping);
    this.mappingControl.setValue(main_mapping);
    console.log(this.mappingControl.value);
  }

  mapVariants(variants: any) {
    if (!variants) { variants = [] };
    if (
      (!this.makeList || !this.makeList.length)
      ||
      (!this.modelList || !this.modelList.length)
      ||
      (!this.variantList || !this.variantList.length)
    ) { return };
    for (const variant of variants) {
      const rto_obj = this.variantList.find((obj: any) => obj.rb_id == variant);
      const model = rto_obj['vehiclemodel_id'];
      const make = (this.modelList.find((obj: any) => obj.rb_id == model))?.manufacturer_id;
      if (!main_mapping.find((val: any) => val.variant == variant)) {
        let obj = main_mapping.find((val: any) => val.model == model && !val.variant);
        if (!obj) {
          obj = { 'make': make, 'model': model, 'variant': variant };
          main_mapping.push(obj);
        } else {
          obj.variant = variant;
        }
      }
    }

    const toRemove = [];
    for (const [idx, obj] of main_mapping.entries()) {
      if (obj.variant && variants.indexOf(obj.variant) === -1) {
        toRemove.push(idx);
      }
    }
    toRemove.forEach((idx: any) => {
      if (main_mapping.filter((obj: any) => obj.model == main_mapping[idx]['model']).length > 1) {
        main_mapping.splice(idx, 1);
      } else {
        main_mapping[idx]['variant'] = null
      }
    });
    main_mapping = removeDuplicates(main_mapping);
    this.mappingControl.setValue(main_mapping);
    console.log(this.mappingControl.value);
    
  }

}

let main_mapping:any = [];