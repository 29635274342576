<div>
    <div class="d-flex justify-content-end align-items-center" style="gap: 10px; padding: 15px;">
        <button class="cancel-btn" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="openPopup()">
            Reject
        </button>
        <button type="button" class="submit-btn" (click)="approve()" [disabled]="submitting || disabled" [ngClass]="{'disabled': disabled || submitting}">
            Approve
        </button>
    </div>
    <!-- Modal -->
    <!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <mat-label class="subHeading">Remark</mat-label>
                    <input type="text" class="rule mt-2" [(ngModel)]="remark"/>
                </div>
                <div class="modal-footer">
                  <p>dhasdadhjad</p>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                        (click)="reject()"
                        [disabled]="!remark || submitting"
                    >
                        Done
                    </button>
                </div>
            </div>
        </div>
    </div> -->


<div class="popup-overlay" *ngIf="showPopup">
  <div class="popup">
    <div class="closeButton" (click)="closePopup2()">x</div>
    <div class="mt-4">
      <div class="modal-content">
        <div class="modal-body">
            <mat-label >Remark</mat-label>
            <input type="text" class="rule mt-2" [(ngModel)]="remark"/>
        </div>
        <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary mt-2"
                data-bs-dismiss="modal"
                (click)="reject()"
                [disabled]="!remark || submitting"
            >
                Done
            </button>
        </div>
    </div>

    </div>
  </div>
</div>
</div>
