<mat-label class="subHeading ">Engine No.<span *ngIf="isRequired" class="star">*</span></mat-label>

<input
    [formControl]="engineNoControl"
    class="reg mt-2"
    placeholder="xxxxxxxxxx"
    [maxlength]="50"

    [value]="engineNoControl.value | uppercase"
/>
<mat-error *ngIf="engineNoControl.value && engineNoControl.hasError('minlength')">
    Should be atleast 6 characters long
</mat-error>

<mat-error *ngIf="engineNoControl.hasError('maxlength')">
  Max length is 50 characters
</mat-error>
