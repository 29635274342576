import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-cpa-cover-field',
  templateUrl: './cpa-cover-field.component.html',
  styleUrls: ['./cpa-cover-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CpaCoverFieldComponent implements OnInit {
  @Input('required') isRequired: boolean = false;

  form: FormGroup;
  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;

    if (this.isRequired) {
      this.form.addControl('cpa', new FormControl(Validators.required));
    }
    this.form.addControl('cpa', new FormControl());
  }

  ngOnDestroy(): void {
    this.form.removeControl('cpa');
  }
}
