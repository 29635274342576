import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

@Component({
  selector: 'app-ncb-field',
  templateUrl: './ncb-field.component.html',
  styleUrls: ['./ncb-field.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class NCBFieldComponent implements OnInit {
  @Input('required') isRequired: boolean = false;

  form: FormGroup;

  ncbForm = new FormGroup({
    'from': new FormControl(),
    'to': new FormControl()
  });

  fromNcbValues:number[] = [0, 20, 25, 35, 45, 50, 60, 65];
  toNcbValues:number[] = [0, 20, 25, 35, 45, 50, 60, 65];

  constructor(private ctrlContainer: FormGroupDirective){};

  ngOnInit(): void {
    this.form = this.ctrlContainer.form;
    if (this.isRequired) {
      this.ncbForm.controls.from.addValidators(Validators.required);
      this.ncbForm.controls.to.addValidators(Validators.required);
    }

    if (this.form.controls['ncb']) {
      this.ncbForm.patchValue(this.form.controls['ncb'].value);
      this.form.removeControl('ncb');
    }

    this.form.addControl('ncb', this.ncbForm);

    this.ncbForm.controls['from'].valueChanges.subscribe((from: number) => {
      this.toNcbValues = this.fromNcbValues.filter((val: number) => val >= from);
      if (this.ncbForm.controls['to'].value < from) {
        this.ncbForm.controls['to'].setValue(null);
      }
    });
  }

  ngOnDestroy(): void {
    this.form.removeControl('ncb');
  }
}
