import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http.service';
import { FormControlService } from 'src/app/shared/services/form-control.service';


@Component({
  selector: 'app-vehicle-category',
  templateUrl: './vehicle-category.component.html',
  styleUrls: ['./vehicle-category.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class VehicleCategoryComponent implements OnInit{
  @Input('lob') lob: string = 'cv';
  @Input('multiple') isMultiSelect:boolean = true;
  @Input('required') isRequired = false;
  @Input('childTransaction') childTransaction = false;

  categories: any = [];
  segments: any = [];
  vehicleClasses: any = [];

  requiredFn = Validators.required;

  form: FormGroup;
  vehicleCategoryControl = new FormControl([]);
  vehicleSegmentControl = new FormControl([]);
  vehicleClassControl = new FormControl([]);

  constructor(private ctrlContainer: FormGroupDirective, private http: HttpService, private controlStateService: FormControlService) {
    this.controlStateService.register({ controlName: 'vehicle_class', control: this.vehicleClassControl });
    this.controlStateService.register({ controlName: 'vehicle_category', control: this.vehicleCategoryControl }, true);
    this.controlStateService.register({ controlName: 'vehicle_segment', control: this.vehicleSegmentControl });
    this.controlStateService.register({ controlName: 'vmv_category', control: this.vehicleCategoryControl }, true);
  };

  ngOnInit() {
    if (this.isRequired) {
      this.vehicleCategoryControl.addValidators(Validators.required);
      this.vehicleSegmentControl.addValidators(Validators.required);
      this.vehicleClassControl.addValidators(Validators.required);
    }
    if(this.childTransaction){
      this.vehicleCategoryControl.addValidators(Validators.required);
    }
    this.form = this.ctrlContainer.form;

    if (this.lob == 'cv') {
      if (this.form.controls['vehicle_class']) {
        this.vehicleClassControl.patchValue(this.form.controls['vehicle_class'].value);
        this.form.removeControl('vehicle_class');
      }
      this.form.addControl('vehicle_class', this.vehicleClassControl);
    
      // this.vehicleCategoryControl.valueChanges.subscribe(
      //   (val: any) => {
      //     if (val && val.length) {
      //       this.getVehicleSegments(val);
      //       this.getVehicleClasses(val);
      //     } else {
      //       this.segments = [];
      //       this.vehicleClasses = [];
      //     }
      //   }
      // );
      this.controlStateService.getControlObservable('vehicle_category').subscribe(
        (val: any) => {
          if (val && val.length) {
            this.getVehicleSegments(val);
            this.getVehicleClasses(val);
          } else {
            this.segments = [];
            this.vehicleClasses = [];
          }
        }
      );
    }
    this.vehicleCategoryControl.valueChanges.subscribe(
      (val: any) => {
        if (val == null) {
          this.vehicleCategoryControl.setValue([]);
        }
      }
    );
    this.vehicleSegmentControl.valueChanges.subscribe((val: any) => {

      if (val == null) {
        this.vehicleSegmentControl.setValue([]);
      }
    });

    if (this.form.controls['vehicle_category']) {
      this.vehicleCategoryControl.patchValue(this.form.controls['vehicle_category'].value);
      this.form.removeControl('vehicle_category');
    }
    if (this.form.controls['vehicle_segment']) {
      this.vehicleSegmentControl.patchValue(this.form.controls['vehicle_segment'].value);
      this.form.removeControl('vehicle_segment');
    }
    if (this.form.controls['vmv_category']){
      this.vehicleCategoryControl.patchValue(this.form.controls['vmv_category'].value);
      this.form.removeControl('vmv_category');
    }

    if (this.lob == 'motor4W') {
      this.form.addControl('vmv_category', this.vehicleCategoryControl);
    } else {
      this.form.addControl('vehicle_category', this.vehicleCategoryControl);
    }
    if (this.lob == 'cv'){
      this.form.addControl('vehicle_segment',this.vehicleSegmentControl)
    }

    this.getVehicleCategories();
  }

  getVehicleCategories() {
    let url = `/services/api/v1/rb_masters/vehicle-categories`;
    if (this.lob == 'motor' || this.lob == 'motor4W') {
      url = `/services/api/v1/rb_masters/motor_vehicle_category`;
    }
    this.http.get(url, {'lob': this.lob}).subscribe(
      (res: any) => {
        this.categories = res['data'];
      }
    )
  }

  getVehicleSegments(category:any) {
    const url = `/services/api/v1/rb_masters/vehicle-segments`;
    const params = { 'category': category };
    this.http.get(url, params).subscribe(
      (res: any)=>{
        this.segments = res['data'];
      }
    )
  }

  getVehicleClasses(category: any) {
    const url = `/services/api/v1/rb_masters/vehicle-classes`;
    const params = { 'category': category };
    this.http.get(url, params).subscribe(
      (res: any) => {
        this.vehicleClasses = res['data'];
      }
    )
  }

  selectionChange(event:any){
    if(!this.isMultiSelect && event.isUserInput && event.source.selected){
      this.vehicleCategoryControl.setValue([event.source.value]);
    }
  }

  ngOnDestroy(): void {
    if (this.form.controls['vehicle_category']) {
      this.form.removeControl('vehicle_category');
    } else if (this.form.controls['vmv_category']) {
      this.form.removeControl('vmv_category');
    }
    this.form.removeControl('vehicle_segment');
    if (this.form.controls['vehicle_class']) {
      this.form.removeControl('vehicle_class');
    }
  }

}
