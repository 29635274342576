<mat-label class="subHeading">{{label}}</mat-label>

<div class="d-flex align-items-center mt-2" style="gap: 10px;">
    <mat-form-field appearance="fill" class="custom-mat-form-field custom-datpicker">
        <input matInput [matDatepicker]="picker1" formControlName="proposer_dob" [max]="today">
        <mat-datepicker-toggle matIconSuffix [for]="picker1">
            <mat-icon matDatepickerToggleIcon>
                <img src="../../../../../assets/icons/calendar.svg">
            </mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
</div>
