<!-- <div class="row">
    <div class="col-4">
        <mat-label class="subHeading">Make</mat-label>
        <mat-select class="make" formControlName="make" multiple>
            <mat-option *ngFor="let makeObj of makeList" [value]="makeObj.rb_id">
                {{makeObj.manufacturer_name}}
            </mat-option>
        </mat-select>
    </div>
    <div class="col-4">
        <mat-label class="subHeading">Model</mat-label>
        <mat-select class="model" formControlName="model" multiple>
            <mat-option *ngFor="let modelObj of modelList" [value]="modelObj.rb_id">
                {{modelObj.model_name}}
            </mat-option>
        </mat-select>
    </div>
    <div class="col-4">
        <mat-label class="subHeading">Variant</mat-label>
        <mat-select class="variant" formControlName="variant" multiple>
            <mat-option *ngFor="let varObj of variantList" [value]="varObj.rb_id">
            {{varObj.variant_name}}
            </mat-option>
        </mat-select>
    </div>
</div> -->


<div class="row">
    <div class="col-md-4 col-12">
        <mat-label class="subHeading">Make<span *ngIf="isRequired" class="star">*</span></mat-label>
        <app-search-dropdown
            [dataSearchKey]="'manufacturer_name'"
            [dataValueKey]="'rb_id'"
            [dataSource]="makeList"
            [control]="makeControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
    <div class="col-md-4 col-12">
        <mat-label class="subHeading">Model<span *ngIf="isRequired" class="star">*</span></mat-label>
        <app-search-dropdown
            [dataSearchKey]="'model_name'"
            [dataValueKey]="'rb_id'"
            [dataSource]="modelList"
            [control]="modelControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
    <div class="col-md-4 col-12"  *ngIf="showVariant">
        <mat-label class="subHeading">Variant<span *ngIf="isRequired" class="star">*</span></mat-label>
        <app-search-dropdown
            [dataSearchKey]="'variant_name'"
            [dataValueKey]="'rb_id'"
            [dataSource]="variantList"
            [control]="variantControl"
            [multiple]="!isRequired"
        ></app-search-dropdown>
    </div>
</div>